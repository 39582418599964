import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "../../../../core/hooks/useTranslation";
import ViewportAnimation from "../../../../components/ViewportAnimation";
import PageContainer from "../../../../components/PageContainer";
import ContentTitle from "../../../../components/ContentTitle";
import ContentRow from "./ContentRow";
import animation from "./_animation";

function PhilosophyText() {
  const { t } = useTranslation();

  return (
    <Box
      minHeight="viewHomePhilosophy"
      marginTop={{ base: 5, md: 0 }}>
      <PageContainer>
        <ViewportAnimation {...animation}>
          <Flex
            alignItems="center"
            minHeight="viewHomePhilosophy"
            height="100%"
            paddingX={{ base: 0, md: "4xl" }}
          >
            <ContentTitle title={t("page@home@philosophy@title")}>
              <ContentRow>{t("page@home@philosophy@text@line-1")}</ContentRow>
              <ContentRow>{t("page@home@philosophy@text@line-2")}</ContentRow>
              <ContentRow>{t("page@home@philosophy@text@line-3")}</ContentRow>
            </ContentTitle>
          </Flex>
        </ViewportAnimation>
      </PageContainer>
    </Box>
  );
}

export default PhilosophyText;
