import animationDesktopCover from "./desktop/animation-cover.json";
import animationDesktopCircleEnUs from "./desktop/animation-circle.en-us.json";
import animationDesktopCircleHyAm from "./desktop/animation-circle.hy-am.json";
import animationDesktopCircleRuRu from "./desktop/animation-circle.ru-ru.json";
import animationDesktopFamily from "./desktop/animation-family.json";
import animationDesktopPoints from "./desktop/animation-dots.json";
import animationMobileCover from "./mobile/animation-cover.json";
import animationMobileCircleEnUs from "./mobile/animation-circle.en-us.json";
import animationMobileCircleHyAm from "./mobile/animation-circle.hy-am.json";
import animationMobileCircleRuRu from "./mobile/animation-circle.ru-ru.json";
import animationMobileFamily from "./mobile/animation-family.json";
import animationMobilePoints from "./mobile/animation-dots.json";

const loader = {
  desktop: (locale) => {
    const circles = {
      "en-US": animationDesktopCircleEnUs,
      "hy-AM": animationDesktopCircleHyAm,
      "ru-RU": animationDesktopCircleRuRu,
    };

    return [
      animationDesktopCover,
      circles[locale],
      animationDesktopFamily,
      animationDesktopPoints,
    ];
  },

  mobile: (locale) => {
    const circles = {
      "en-US": animationMobileCircleEnUs,
      "hy-AM": animationMobileCircleHyAm,
      "ru-RU": animationMobileCircleRuRu,
    };

    return [
      animationMobileCover,
      circles[locale],
      animationMobileFamily,
      animationMobilePoints,
    ];
  },
};

export default (mode, locale) => {
  if (!loader[mode]) {
    return loader.desktop[locale];
  }

  return loader[mode](locale);
};
