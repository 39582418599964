import React, { useState, useMemo, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import Lottie from "lottie-react";
import Viewport from "../../../../components/Viewport";
import { useTranslation } from "../../../../core/hooks/useTranslation";
import ViewportAnimation from "../../../../components/ViewportAnimation";
import viewportAnimation from "./viewport-animation";
import DotHandler from "./DotHandler";
import animationLoader from "./animations";

const itemsStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const scales = {
  desktop: {
    cover: 1,
    circle: 1,
    family: 1,
    points: 1,
  },
  mobile: {
    cover: 1,
    circle: 2.4,
    family: 2,
    points: 1.9,
  },
};

function PhilosophyAnimation({ viewMode }) {
  const { language } = useTranslation();

  const [refDotsSvg, setRefDotsSvg] = useState(null);
  const [animated, setAnimated] = useState(false);

  const [animationCover, animationCircle, animationFamily, animationPoints] =
    useMemo(() => animationLoader(viewMode, language), []);

  const onDelayEnd = useCallback(() => {
    setTimeout(() => {
      setAnimated(true);
    }, 4800);
  }, []);

  return (
    <Viewport delay={500} onDelayEnd={onDelayEnd}>
      {() => (
        <>
          {/* <ViewportAnimation {...viewportAnimation}> */}
          <Box position="relative">
            <Box transform={`scale(${scales[viewMode].cover})`} zIndex={1}>
              <Lottie animationData={animationCover} loop={false} />
            </Box>
            <Box
              {...itemsStyle}
              transform={`scale(${scales[viewMode].circle})`}
              zIndex={2}
            >
              <Lottie animationData={animationCircle} loop={false} />
            </Box>
            <Box
              {...itemsStyle}
              transform={`scale(${scales[viewMode].family})`}
              zIndex={animated ? 3 : 4}
            >
              <Lottie animationData={animationFamily} loop={false} />
            </Box>

            <Box
              {...itemsStyle}
              transform={`scale(${scales[viewMode].points})`}
              zIndex={animated ? 4 : 3}
              ref={setRefDotsSvg}
            >
              <Lottie animationData={animationPoints} loop={false} />
              {refDotsSvg && (
                <DotHandler viewMode={viewMode} container={refDotsSvg} />
              )}
            </Box>
          </Box>
          {/* </ViewportAnimation> */}
        </>
      )}
    </Viewport>
  );
}

export default PhilosophyAnimation;
