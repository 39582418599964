// +-----------------------------------------------------------------+
// | don't judge me, there is no other way to this                   |
// | starting of GRDON                                               |
// +-----------------------------------------------------------------+

import React, { useEffect } from "react";
import { useTranslation } from "../../../../core/hooks/useTranslation";
import data from "./_data";
import "./style.css";

/**
 * @param {MouseEvent} event
 * @param {HTMLDivElement} tooltip
 * @param {HTMLDivElement} container
 * @param {boolean} visible
 */
const setTooltipVisibleState = (event, svgDot, tooltip, viewMode, visible) => {
  if (!visible) {
    tooltip.style.cssText = "";
    tooltip.removeAttribute("style");
    return;
  }

  const rectSvgDot = svgDot.getBoundingClientRect();
  const top = window.scrollY + rectSvgDot.top + 3;

  if ("mobile" === viewMode) {
    tooltip.style.textAlign = "center";
    tooltip.style.top = `${top + 30}px`;
    tooltip.style.left = "25px";
    tooltip.style.right = "25px";
  } else {
    tooltip.style.top = `${top}px`;

    const isLeftSide =
      window.innerWidth / 2 > rectSvgDot.left + rectSvgDot.width / 2;
    if (isLeftSide) {
      tooltip.style.left = `${rectSvgDot.right}px`;
    } else {
      const rect = tooltip.getBoundingClientRect();
      tooltip.style.left = `${rectSvgDot.left - rect.width}px`;
    }
  }

  tooltip.style.opacity = 1;
  tooltip.style.visibility = "visible";
};

const registerDotHoverListener = (svgDot, tooltip, viewMode) => {
  svgDot.addEventListener("mouseover", (e) =>
    setTooltipVisibleState(e, svgDot, tooltip, viewMode, true)
  );
  svgDot.addEventListener("mouseleave", (e) =>
    setTooltipVisibleState(e, svgDot, tooltip, viewMode, false)
  );
};

const allDots = [];
let parentVisibleIndex = -1;

const showElementsByIndexes = (container, indexes) => {
  indexes.forEach((index) => {
    const selector = `svg > g > g:nth-child(${index + 1})`;
    const svgDot = container.querySelector(selector);
    if (svgDot) {
      svgDot.classList.add("slave-visible");
    }
  });
};

const registerParentDotListener = (
  container,
  svgDot,
  tooltip,
  viewMode,
  row
) => {
  svgDot.addEventListener("click", (e) => {
    allDots.forEach((svgDot) => {
      svgDot.classList.remove("slave-visible");
      svgDot.classList.remove("parent-toggled");
    });

    if (parentVisibleIndex === row.index) {
      parentVisibleIndex = -1;
      setTooltipVisibleState(e, svgDot, tooltip, viewMode, false);

      return;
    }

    parentVisibleIndex = row.index;
    svgDot.classList.add("parent-toggled");

    const showElements = data
      .filter((rowOther) => {
        if (rowOther.index === row.index) {
          return false;
        }

        if (!!rowOther.group || !!rowOther.childs) {
          return false;
        }

        if (!rowOther.hasOwnProperty("parent")) {
          return false;
        }

        return rowOther.parent === row.index;
      })
      .reduce((acc, value) => [...acc, value.index], []);

    showElementsByIndexes(container, showElements);
  });
};

const processRow = (t, container, row, viewMode) => {
  const selector = `svg > g > g:nth-child(${row.index + 1})`;
  const svgDot = container.querySelector(selector);
  if (!svgDot) {
    return null;
  }

  svgDot.classList.add("philosophy-dot");
  svgDot.style.display = "block";
  allDots.push(svgDot);

  const tooltip = document.createElement("div");
  tooltip.innerText = t(row.name);
  tooltip.classList.add("philosophy-tooltip");

  document.body.append(tooltip);

  if (row.group) {
    svgDot.classList.add("philosophy-dot-parent");
    svgDot.setAttribute("data-group", row.group);
    registerParentDotListener(container, svgDot, tooltip, viewMode, row);
  } else {
    svgDot.classList.add("philosophy-dot-slave");
  }

  registerDotHoverListener(svgDot, tooltip, viewMode);
  return tooltip;
};

function DotHandler({ viewMode, container }) {
  const { t, language } = useTranslation();

  useEffect(() => {
    if ("undefined" === typeof document) {
      return;
    }

    if (container instanceof HTMLDivElement) {
      const selector = `svg > g > g`;
      const svgDots = container.querySelectorAll(selector);
      for (let index = 0; index < svgDots.length; ++index) {
        svgDots[index].setAttribute("data-index", index);
        svgDots[index].setAttribute("data-view-mode", viewMode);
      }

      const tooltips = data
        .map((row) => processRow(t, container, row, viewMode))
        .filter((row) => null !== row);

      return () => {
        tooltips.forEach((tooltip) => {
          try {
            document.body.removeChild(tooltip);
          } catch (e) {
            console.error("--->>> Failed to remove tooltip:", {
              tooltip,
              error: e,
            });
          }
        });
      };
    }
  }, [container, language]);

  return null;
}

export default DotHandler;
