const data = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "20rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.75,
  },
};

export default data;
