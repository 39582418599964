/**
 * interface DotInterface {
 *   index: number;
 *   name: string;
 * }
 *
 * default exported Array<DotInterface>
 */
export default [
  // #region Safety/Security
  // #region Safety/Security - Education
  {
    index: 0,
    name: "page@home@philosophy@investment@safety-security@e@education",
    group: "safety",
    childs: [1, 2, 3],
  },
  {
    index: 1,
    name: "page@home@philosophy@investment@safety-security@e@education@youth-sporting",
    parent: 0,
  },
  {
    index: 2,
    name: "page@home@philosophy@investment@safety-security@e@education@professional-seminars",
    parent: 0,
  },
  {
    index: 3,
    name: "page@home@philosophy@investment@safety-security@e@education@art-education",
    parent: 0,
  },
  // #endregion

  // #region Safety/Security - Physical safety
  {
    index: 4,
    name: "page@home@philosophy@investment@safety-security@e@physical",
    group: "safety",
    childs: [5, 6, 7],
  },
  {
    index: 5,
    name: "page@home@philosophy@investment@safety-security@e@physical@safe-infrastructure",
    parent: 4,
  },
  {
    index: 6,
    name: "page@home@philosophy@investment@safety-security@e@physical@residential-construction",
    parent: 4,
  },
  {
    index: 7,
    name: "page@home@philosophy@investment@safety-security@e@physical@sustainability",
    parent: 4,
  },
  // #endregion

  // #region Safety/Security - Personal financial safety
  {
    index: 8,
    name: "page@home@philosophy@investment@safety-security@e@personal",
    group: "safety",
    childs: [9, 10, 11],
  },
  {
    index: 9,
    name: "page@home@philosophy@investment@safety-security@e@personal@fund-investment",
    parent: 8,
  },
  {
    index: 10,
    name: "page@home@philosophy@investment@safety-security@e@personal@personal-wealth",
    parent: 8,
  },
  {
    index: 11,
    name: "page@home@philosophy@investment@safety-security@e@personal@financial-insurance",
    parent: 8,
  },
  // #endregion
  // #endregion

  // #region Comfort
  // #region Comfort - Infrastructure
  {
    index: 48,
    name: "page@home@philosophy@investment@comfort@e@infrastructure",
    group: "comfort",
    childs: [49, 50, 51, 52],
  },
  {
    index: 49,
    name: "page@home@philosophy@investment@comfort@e@infrastructure@transportation",
    parent: 48,
  },
  {
    index: 50,
    name: "page@home@philosophy@investment@comfort@e@infrastructure@energy",
    parent: 48,
  },
  {
    index: 51,
    name: "page@home@philosophy@investment@comfort@e@infrastructure@clean-environment",
    parent: 48,
  },
  {
    index: 52,
    name: "page@home@philosophy@investment@comfort@e@infrastructure@parking-traffic",
    parent: 48,
  },
  // #endregion

  // #region Comfort - Affordable habitat
  {
    index: 40,
    name: "page@home@philosophy@investment@comfort@e@affordable",
    group: "comfort",
    childs: [41],
  },
  {
    index: 41,
    name: "page@home@philosophy@investment@comfort@e@affordable@residential-housing",
    parent: 40,
  },
  // #endregion

  // #region Comfort - Leisure, rest and free time
  {
    index: 42,
    name: "page@home@philosophy@investment@comfort@e@leisure",
    group: "comfort",
    childs: [43, 44, 45, 46, 47],
  },
  {
    index: 43,
    name: "page@home@philosophy@investment@comfort@e@leisure@hotels-casino",
    parent: 42,
  },
  {
    index: 44,
    name: "page@home@philosophy@investment@comfort@e@leisure@resorts",
    parent: 42,
  },
  {
    index: 45,
    name: "page@home@philosophy@investment@comfort@e@leisure@sport",
    parent: 42,
  },
  {
    index: 46,
    name: "page@home@philosophy@investment@comfort@e@leisure@parks",
    parent: 42,
  },
  {
    index: 47,
    name: "page@home@philosophy@investment@comfort@e@leisure@events",
    parent: 42,
  },
  // #endregion

  // #region Comfort - Social comfort
  {
    index: 37,
    name: "page@home@philosophy@investment@comfort@e@social-comfort",
    group: "comfort",
    childs: [38, 39],
  },
  {
    index: 38,
    name: "page@home@philosophy@investment@comfort@e@social-comfort@event-production",
    parent: 37,
  },
  {
    index: 39,
    name: "page@home@philosophy@investment@comfort@e@social-comfort@restaurants",
    parent: 37,
  },
  // #endregion

  // #region Comfort - Comfortable business and professional environment
  {
    index: 53,
    name: "page@home@philosophy@investment@comfort@e@business",
    group: "comfort",
    childs: [54, 55, 56],
  },
  {
    index: 54,
    name: "page@home@philosophy@investment@comfort@e@business@business-conferencing-centers",
    parent: 53,
  },
  {
    index: 55,
    name: "page@home@philosophy@investment@comfort@e@business@summit-organization",
    parent: 53,
  },
  {
    index: 56,
    name: "page@home@philosophy@investment@comfort@e@business@marketing-service",
    parent: 53,
  },
  // #endregion

  // #region Comfort - Comfortable living
  {
    index: 34,
    name: "page@home@philosophy@investment@comfort@e@living",
    group: "comfort",
    childs: [35, 36],
  },
  {
    index: 35,
    name: "page@home@philosophy@investment@comfort@e@living@innovative",
    parent: 34,
  },
  {
    index: 36,
    name: "page@home@philosophy@investment@comfort@e@living@shopping-malls",
    parent: 34,
  },
  // #endregion
  // #endregion

  // #region Health
  // #region Health - Innovative environmentally clean farming
  {
    index: 21,
    name: "page@home@philosophy@investment@health@e@clean-farming",
    group: "health",
    childs: [22, 23],
  },
  {
    index: 22,
    name: "page@home@philosophy@investment@health@e@clean-farming@wood",
    parent: 21,
  },
  {
    index: 23,
    name: "page@home@philosophy@investment@health@e@clean-farming@energy",
    parent: 21,
  },
  // #endregion

  // #region Health - Effective healthcare
  {
    index: 12,
    name: "page@home@philosophy@investment@health@e@effective-healthcare",
    group: "health",
    childs: [13, 14, 15, 16, 17],
  },
  {
    index: 13,
    name: "page@home@philosophy@investment@health@e@effective-healthcare@hospitals",
    parent: 12,
  },
  {
    index: 14,
    name: "page@home@philosophy@investment@health@e@effective-healthcare@spa-medical",
    parent: 12,
  },
  {
    index: 15,
    name: "page@home@philosophy@investment@health@e@effective-healthcare@rehabilitation",
    parent: 12,
  },
  {
    index: 16,
    name: "page@home@philosophy@investment@health@e@effective-healthcare@dental",
    parent: 12,
  },
  {
    index: 17,
    name: "page@home@philosophy@investment@health@e@effective-healthcare@elderly",
    parent: 12,
  },
  // #endregion

  // #region Health - Quality medicine and medical appliances
  {
    index: 18,
    name: "page@home@philosophy@investment@health@e@quality",
    group: "health",
    childs: [19, 20],
  },
  {
    index: 19,
    name: "page@home@philosophy@investment@health@e@quality@biotech",
    parent: 18,
  },
  {
    index: 20,
    name: "page@home@philosophy@investment@health@e@quality@health-beauty",
    parent: 18,
  },
  // #endregion

  // #region Health - Healthy food and organic farming
  {
    index: 29,
    name: "page@home@philosophy@investment@health@e@healthy-food",
    group: "health",
    childs: [30, 31, 32, 33],
  },
  {
    index: 30,
    name: "page@home@philosophy@investment@health@e@healthy-food@organic-products",
    parent: 29,
  },
  {
    index: 31,
    name: "page@home@philosophy@investment@health@e@healthy-food@herbs",
    parent: 29,
  },
  {
    index: 32,
    name: "page@home@philosophy@investment@health@e@healthy-food@restaurants",
    parent: 29,
  },
  {
    index: 33,
    name: "page@home@philosophy@investment@health@e@healthy-food@organic-winner",
    parent: 29,
  },
  // #endregion

  // #region Health - Healthy lifestyle and living
  {
    index: 24,
    name: "page@home@philosophy@investment@health@e@lifestyle",
    group: "health",
    childs: [25, 26, 27, 28],
  },
  {
    index: 25,
    name: "page@home@philosophy@investment@health@e@lifestyle@spa-beauty",
    parent: 24,
  },
  {
    index: 26,
    name: "page@home@philosophy@investment@health@e@lifestyle@sport-resorts",
    parent: 24,
  },
  {
    index: 27,
    name: "page@home@philosophy@investment@health@e@lifestyle@sport-events",
    parent: 24,
  },
  {
    index: 28,
    name: "page@home@philosophy@investment@health@e@lifestyle@active-tourism",
    parent: 24,
  },
  // #endregion
  // #endregion
];
