import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";

import PhilosophyText from "./PhilosophyText";
import PhilosophyAnimation from "./PhilosophyAnimation";
// import PhilosophyPoints from "./PhilosophyPoints";

function Philosophy({ desktopHeight }) {
  const viewMode = useMemo(() => {
    if ("undefined" === typeof window) {
      return;
    }

    return window.innerWidth < 700 ? "mobile" : "desktop";
  }, []);

  return (
    <Box>
      <PhilosophyText />
      {/* <PhilosophyPoints desktopHeight={desktopHeight} /> */}

      <PhilosophyAnimation viewMode={viewMode} />
    </Box>
  );
}

export default Philosophy;
