import React from "react";
import { Box, Text } from "@chakra-ui/react";

function ContentRow({ children }) {
  return (
    <Box marginBottom="2xl">
      <Text fontSize="md" textAlign="justify">{children}</Text>
    </Box>
  );
}

export default ContentRow;
